<template>
  <div class="chip-acmpl-main-wrap">
    <v-chip
      :class="['person-label', chipMenuModel ? 'chip-acmpl-active' : '', required ? 'required-chip' : '']"
      small
      label
      outlined
      color="#9e9e9e"
    >
        <v-text-field
          :value="chipAutocompleteSearch"
          :placeholder="'🔍︎ ' + $t('Добавить')"
          hide-details
          dense
          class="mt-0 chip-acmpl-input"
          @input="onChipAutocompleteInput($event)"
          ref="chipInputRef"
          @keydown.40="moveDown" 
          @keydown.38="moveUp"
          @keydown.13="itemClick(items[selectedIndex], true)"
          :autofocus="isNeedToSetFocus"
        ></v-text-field>
    </v-chip>
    <div
      v-show="chipMenuModel"
      :class="['cacd-wrap', positionDropdown]"
      ref="menuRef"
      >
      
        <v-virtual-scroll
          v-if="items.length > 0 && !isLoadingData"
          :items="items"
          :item-height="40"
          :bench="10"
          :class="['virt-scrl-list-tree', 'virt-scrl-list-tree-' + idVScroll]"
          :max-height="160"
        >
          <template v-slot:default="{ item, index }">
            <v-list-item
                :key="index"
                :value="item"
                @click="itemClick(item)"
                @mouseover="itemMouseover(index)"
                :class="{activeListItem: selectedIndex === index}"
                v-tooltip="{ 
                  content: listItemTooltip(item), 
                  show: selectedIndex === index && chipMenuModel, 
                  placement: 'left',
                  trigger: 'manual',
                  boundariesElement: boundariesElementTooltips
                }"
            >
              <v-list-item-icon>
                <v-icon v-if="item.position == 0" color="red darken-2" small>
                  mdi-account-tie
                </v-icon>
                <v-icon v-else-if="item.position == 1" color="red darken-2" small>
                  mdi-account
                </v-icon>
                <v-icon v-else-if="item.position == 2" color="green darken-2" small>
                  mdi-account-tie
                </v-icon>
                <v-icon v-else-if="item.position == 3" color="green darken-2" small>
                  mdi-account
                </v-icon>
                <v-icon v-else-if="item.element_type == 'Department'" small>
                  mdi-account-group
                </v-icon>
                <v-icon v-else-if="item.element_type == 'WorkPlace' || item.element_type == 'Person'" small>
                  mdi-account
                </v-icon>
                <v-icon v-else-if="item.element_type == 'Enterprise' || item.element_type == 'Contractor' || itemsParams.only_work_enterprises" small>
                  fas fa-landmark
                </v-icon>
                <v-icon v-else-if="item.element_category == 'ErrorMessage' && item.element_type == 'Empty'" small>
                  fas fa-exclamation-circle
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                  <v-list-item-title>
                      <span
                          :class="['font-weight-medium', 'text-uppercase']"
                          v-html="item.name"
                      >
                      </span>
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <div
          v-else-if="isLoadingData"
          class="div-table-row"
        >
          <div class="cfl-placeholder">
            {{ $t("Загрузка") }}
            <v-progress-linear
                color="#a94442"
                indeterminate
                rounded
                height="2"
            ></v-progress-linear>
          </div>
        </div>
        <div
          v-else
          class="div-table-row"
        >
          <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
        </div>
      
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import sys from '@/services/system';
import _ from 'lodash';

export default {
    name: "SelectMembersChip",
    props: {
        targetType: {
            type: String,
            default: null
        },
        itemsParams: {
            type: Object,
            default: () => null
        },
        positionDropdown: {
          type: String,
          default: 'bottom'
        },
        required: {
          type: Boolean,
          default: false
        }
    },
    data: () => ({
        chipMenuModel: false,
        chipAutocompleteSearch: "",
        contBox: document.getElementsByClassName('content-box')[0],
        selectedIndex: 0,
        idVScroll: window.crypto.getRandomValues(new Uint16Array(1))[0],
        boundariesElementTooltips:  null,
    }),
    computed: {
        ...mapGetters('global/selectMembersChip',
            { 
                allowedItems: 'getItems', //данные без учета поиска
                isLoadingData: 'isLoading', //признак, что данные сейчас загружаются
                isNeedToSetFocus: 'isNeedToSetFocus' //признак фокуса на поле
            }),
        items() {
            return sys.checkSearchWithScoreGetArray(this.chipAutocompleteSearch, this.allowedItems, "name");
        }
    },
    methods: {
        ...mapActions('global/selectMembersChip', { updateAllowedItems: 'updateItems' }),
        ...mapMutations('global/selectMembersChip', { updateAutoFocus: 'SET_AUTO_FOCUS' }),
        onChipAutocompleteInput: _.debounce(async function (value) {
            this.chipAutocompleteSearch = value;
            this.selectedIndex = 0;
            if (this.chipAutocompleteSearch?.length > 2) {
                this.chipMenuModel = true;

                //позиционирование выпадающего списка(left/right)
                let screenCenter = this.contBox.clientWidth / 2;
                let element = this.$refs.chipInputRef;
                let elementOffset = this.getOffset(element.$el);

                let menu = this.$refs.menuRef;
                
                if(elementOffset.left <= screenCenter){
                  menu.style.left = "0";
                  menu.style.right = "initial";
                  menu.style.borderTopRightRadius = "4px";
                  menu.style.borderTopLeftRadius = "0";
                }else{
                  menu.style.left = "initial";
                  menu.style.right = "0";
                  menu.style.borderTopRightRadius = "0";
                  menu.style.borderTopLeftRadius = "4px";
                } 

                //грузим элементы
                await this.updateAllowedItems({ params: this.itemsParams });

                //если элемент расположен слишком близко к нижней части экрана, то промотаем главный контейнер в самый низ автоматически
                if((this.contBox.clientHeight - elementOffset.top) < 200){
                  this.contBox.scrollTo(0, this.contBox.scrollHeight);
                }
            }
            else {
                this.chipMenuModel = false;
            }
        }, 250),
        itemClick(selected, keyboardCall = false) {
                 
            if (!selected)
                return;
            
            let member = null;
            if (this.itemsParams.only_work_enterprises){
              member = selected;
            }
            else {
              switch (selected.element_category)
              {
                  case 'InnerExecuter':
                  case 'OuterExecuter':
                      member = { type: 1, enterprise: selected.enterpriseid, workplaceId: selected.id, employeeId: selected.employeeid, name: selected.name, description: "", esedoMember: null, inner: selected.element_category === 'InnerExecuter', contacts: selected.contacts };
                      break;

                  case 'OuterESEDO':
                      member = { type: 0, enterprise: selected.id, workplaceId: null, employeeId: null, name: selected.name, description: "", esedoMember: true, inner: false, contacts: selected.contacts };
                      break;

                  case 'OuterContractors':
                      member = { type: 0, enterprise: selected.id, workplaceId: null, employeeId: null, name: selected.name, description: "", esedoMember: false, inner: false, contacts: selected.contacts };
                      break;

                  case 'OuterPersons':
                      member = { type: 1, enterprise: selected.id, workplaceId: null, employeeId: null, name: selected.name, description: "", esedoMember: false, inner: false, contacts: selected.contacts };
                      break;

                  default:
                      member = null;
                      break;
              }
            }

            let valueForUpdateTargetProperty = this.createValueFromSelectedMemberItem(member);

            if (valueForUpdateTargetProperty)
                this.$emit('on-select', { value: valueForUpdateTargetProperty});
            
            this.clearState();

            if(keyboardCall){
              this.updateAutoFocus(true);
            }
        },
        itemMouseover(id){
          this.selectedIndex = id;
        },
        getOffset(el) {
            const rect = el.getBoundingClientRect();
            return {
              left: rect.left + window.scrollX,
              top: rect.top + window.scrollY
            };
        },
        createValueFromSelectedMemberItem(x) {
            if (!x)
                return null;

            switch (this.targetType)
            {
                case 'Recipient':
                    return { ContractorType: x.type, Id: x.enterprise, IsEmployee: false, Name: x.name, IsEsedo: x.esedoMember };

                case 'Member':
                    return x;

                default:
                    return null;
            }
        },
        moveDown(e) {
          e.preventDefault();
          if (this.selectedIndex + 2 <= this.items.length) {
            this.selectedIndex++;
            this.scrollListMenu(this.selectedIndex * 40);
          }
        },
        moveUp(e) {
          e.preventDefault();
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
            this.scrollListMenu(this.selectedIndex * 40);
          }
        },
        scrollListMenu(offset){
            const root = document.querySelector(".virt-scrl-list-tree" + '-' + this.idVScroll);
            if(root){
                root.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }  
        },
        clearState (){
          this.chipMenuModel = false;
          this.chipAutocompleteSearch = "";
          this.selectedIndex = 0;
        },
        listItemTooltip(item){
          let resultStr = '<div style="text-align:left;">';
          resultStr += item.name ? '<span style="font-weight:bold;text-transform:uppercase;">' + item.name + '</span>' : "";
          resultStr += item.jobtitle ? '<br>' + item.jobtitle : '';
          resultStr += item.enterprisename ? '<br>' + item.enterprisename : '';
          if(item.contacts && item.contacts.length > 0){
            item.contacts.forEach(function(contact, index) {
              let contactIcon = contact.type === 'email' ? '<b>&#9993;</b>' : '&#9742;'
              if (index === 0) {
                resultStr += '<br>' + contactIcon + contact.value;
              } else {
                resultStr += ', ' + contactIcon + contact.value;
              }
            });
          }
          resultStr += '</div>';
          return resultStr;
        }
    },
    created () {
      document.addEventListener('click', (e) => {
        //закрываем облачко и сбрасываем поиск если клик был по input
        if(e?.target?.value != this.chipAutocompleteSearch){
          this.clearState();
        }
      });
    },
    watch: {},
    beforeDestroy(){
      this.updateAutoFocus(false);
    }
}
</script>